import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-info">
        <div>
          <div>
            <b>Inwestor</b>
          </div>
          <div>GRUPA IB BANASZAK-KACZMAREK</div>
          <div>SPÓŁKA JAWNA</div>
          <div>NIP: 6972381760</div>
          <div>Modrzewiowa 29, 64-100 Leszno</div>
        </div>
        <div>
          <div>
            <b>Kontakt</b>
          </div>
          <div>
            <a href="tel:+48515086899">
              <i className="bi bi-telephone"></i> +48 515 086 899
            </a>
          </div>
          <div>
            <a href="tel:+48518933779">
              <i className="bi bi-telephone"></i> +48 518 933 779
            </a>
          </div>
          <div>
            <a href="mailto:biuro@grupaib.pl">
              <i className="bi bi-envelope"></i> biuro@grupaib.pl
            </a>
          </div>
        </div>
      </div>
      <div className="disclaimer">
        Informujemy, że wszelkie elementy zielone, rzeczy ruchome, wygląd
        zewnętrzny i wewnętrzny budynków realizowanych w ramach poszczególnych
        przedsięwzięć deweloperskich oraz sposób zagospodarowania nieruchomości
        sąsiednich, przedstawione w wizualizacjach na niniejszej stronie, mają
        charakter poglądowy i stanowią jedynie ogólny, przewidywany sposób
        wykonania inwestycji. Oznacza to, że wizualizacje te nie przedstawiają
        ostatecznych, docelowych właściwości inwestycji wraz z najbliższym
        otoczeniem i tym samym nie stanowią próbki lub wzoru w rozumieniu art.
        5561 § 1pkt 2 KC. Sposób wykonania inwestycji, w tym zagospodarowania
        nieruchomości wspólnej/jej części oraz nieruchomości sąsiednich może się
        w pewnym zakresie zmienić.
      </div>
    </div>
  );
};

export default Footer;
