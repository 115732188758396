import * as React from "react";
import imgpath from "../images/przyziemie.png";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import { getStatusLabel } from "../utils";

const Przyziemie = (props) => {
  const paths = {
    1: "m 549.87838,504.18878 308.60948,-0.29373 -0.004,-98.29963 h 60.1179 l -0.80157,-125.8468 -57.71318,-0.80157 -1.60315,-60.1179 -167.52854,-0.80157 -2.40472,-74.54619 -137.06881,-0.80158 z",
    2: "m 858.48786,503.89505 -381.25393,1.33305 2.66611,481.2331 374.58865,1.33306",
    3: "m 477.23393,505.2281 -379.920866,1.33306 5.332226,477.23393 377.25475,2.66611",
    4: "M 407.19857,504.18878 97.313064,506.56116 98.593354,406.397 H 41.681743 l -0.801572,-121.83895 56.110039,0.80158 1.603144,-68.13362 169.131686,0.80157 0.80157,-75.34777 138.67196,0.80158",
  };

  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    Axios.get("https://bialadolina.com/api/mieszkanias").then((result) => {
      if (result.data) {
        const floorItems = result.data.filter(
          (b) =>
            b.budynek === params.budynek.toUpperCase() &&
            b.pietro.toString() === params.poziom.toString()
        );
        if (floorItems.length) {
          setData(floorItems);
        } else {
          navigate("/");
        }
      }
    });
  }, [navigate, params.budynek, params.poziom]);

  const determineColor = (status) => {
    if (status === "dostepny") return "green";
    if (status === "rezerwacja") return "yellow";
    return "red";
  };

  const determineCursor = (status) => {
    if (status === "dostepny" || status === "rezerwacja") return "pointer";
    return "default";
  };

  const renderStyle = (item) => {
    return {
      fill: determineColor(item.status),
      stroke: determineColor(item.status),
      strokeWidth: 5,
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeOpacity: 0.4,
      fillOpacity: 0.3,
      cursor: determineCursor(item.status),
    };
  };

  const renderPaths = (items) => {
    return items.map((item) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        data-tip
        data-for={item.id.toString()}
        style={{ outline: "none" }}
        key={item.id.toString()}
      >
        <path
          style={renderStyle(item)}
          d={paths[item.numer_lokalu]}
          onClick={() =>
            item.status !== "niedostepny" &&
            navigate(`/m/${item.budynek}/${item.numer_lokalu}`)
          }
        />
      </a>
    ));
  };

  const renderTooltips = (items) => {
    return items.map((item, i) => (
      <ReactTooltip id={item.id.toString()} type={"light"} key={i}>
        <span>{`${item.numer_lokalu} - ${getStatusLabel(item.status)}`}</span>
      </ReactTooltip>
    ));
  };

  return (
    <>
      <svg
        viewBox="0 0 954.66669 1018.6667"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <image
          width={954.66669}
          height={1018.6667}
          preserveAspectRatio="none"
          xlinkHref={imgpath}
        />
        {data && renderPaths(data)}
      </svg>
      {data && renderTooltips(data)}
    </>
  );
};

export default Przyziemie;
