import imgpath from "../images/pietro.png";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import { getStatusLabel } from "../utils";

const Pietro = (props) => {
  const paths = {
    9: "m571.302 459.406 1.576-278.165H710.78l.788 72.496 169.42.788.789 63.828 59.1.788 1.576 126.869-70.132-.788-2.908 97.65-297.322.062",
    10: "m696.618 1007.511.68-87.077h14.286l.68-88.438h155.787l1.36-289.124-368.463 1.185.242 463.978",
    11: "M500.948 544.057H121.893l.743 285.407 167.23 1.486 2.23 88.446 11.96 1.366-.09 86.566 197.224.707",
    12: "m430.34 541.827-308.447 2.23v-98.852l-60.204-.743.744-125.609 58.716.743 1.487-64.662 167.23-.744 1.487-74.324H430.34",
  };

  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    Axios.get("https://bialadolina.com/api/mieszkanias").then((result) => {
      if (result.data) {
        const floorItems = result.data.filter(
          (b) =>
            b.budynek === params.budynek.toUpperCase() &&
            b.pietro.toString() === params.poziom.toString()
        );
        if (floorItems.length) {
          setData(floorItems);
        } else {
          navigate("/");
        }
      }
    });
  }, [navigate, params.budynek, params.poziom]);

  const determineColor = (status) => {
    if (status === "dostepny") return "green";
    if (status === "rezerwacja") return "yellow";
    return "red";
  };

  const determineCursor = (status) => {
    if (status === "dostepny" || status === "rezerwacja") return "pointer";
    return "default";
  };

  const renderStyle = (item) => {
    return {
      fill: determineColor(item.status),
      stroke: determineColor(item.status),
      strokeWidth: 5,
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeOpacity: 0.4,
      fillOpacity: 0.3,
      cursor: determineCursor(item.status),
    };
  };

  const renderPaths = (items) => {
    return items.map((item) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        data-tip
        data-for={item.id.toString()}
        style={{ outline: "none" }}
        key={item.id.toString()}
      >
        <path
          style={renderStyle(item)}
          d={paths[item.numer_lokalu]}
          onClick={() =>
            item.status !== "niedostepny" &&
            navigate(`/m/${item.budynek}/${item.numer_lokalu}`)
          }
        />
      </a>
    ));
  };

  const renderTooltips = (items) => {
    return items.map((item, i) => (
      <ReactTooltip id={item.id.toString()} type={"light"} key={i}>
        <span>{`${item.numer_lokalu} - ${getStatusLabel(item.status)}`}</span>
      </ReactTooltip>
    ));
  };

  return (
    <>
      <svg
        viewBox="0 0 1006.667 1104"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <image
          width={1006.667}
          height={1104}
          preserveAspectRatio="none"
          xlinkHref={imgpath}
        />
        {data && renderPaths(data)}
      </svg>
      {data && renderTooltips(data)}
    </>
  );
};

export default Pietro;
