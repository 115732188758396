import * as React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import usePageTracking from "./utils/usePageTracking";
import { Button } from "react-bootstrap";

const CustomNavbar = () => {
  const [navBackground, setNavBackground] = useState(false);
  const navRef = React.useRef();
  navRef.current = navBackground;
  const location = useLocation();
  usePageTracking();

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      const match = matchPath(
        { path: "/poziom/:budynek/:poziom" },
        location.pathname
      );
      const match2 = matchPath(
        { path: "/m/:budynek/:mieszkanie" },
        location.pathname
      );
      const match3 = matchPath(
        { path: "/budynek/:budynek" },
        location.pathname
      );
      const match4 = matchPath({ path: "/kontakt" }, location.pathname);
      if (navRef.current !== show) {
        if (!show) {
          //don't make transparent on these 2 paths
          if (!match && !match2 && !match3 && !match4) {
            setNavBackground(show);
          }
        } else {
          setNavBackground(show);
        }
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  useEffect(() => {
    const match = matchPath(
      { path: "/poziom/:budynek/:poziom" },
      location.pathname
    );
    const match2 = matchPath(
      { path: "/m/:budynek/:mieszkanie" },
      location.pathname
    );
    const match3 = matchPath({ path: "/budynek/:budynek" }, location.pathname);
    const match4 = matchPath({ path: "/kontakt" }, location.pathname);

    if (match || match2 || match3 || match4) {
      setNavBackground(true);
    } else {
      setNavBackground(false);
    }
  }, [location]);

  return (
    <Navbar
      expand="lg"
      fixed="top"
      className={navBackground ? "white-navbar" : "transparent-navbar"}
    >
      <Container>
        <Navbar.Brand href="/">
          <div
            className="logo"
            style={{ color: `${navBackground ? "#000" : "#fff"}` }}
          >
            BIAŁA DOLINA
          </div>
        </Navbar.Brand>

        <div className="navbar-links">
          <div className="hide-under-w-420">
            <a
              href="tel:+48515086899"
              style={{ color: `${navBackground ? "#767676" : "#fff"}` }}
            >
              +48 515 086 899
            </a>
          </div>
          <div className="hide-under-w-420">
            <a
              href="tel:+48518933779"
              style={{ color: `${navBackground ? "#767676" : "#fff"}` }}
            >
              +48 518 933 779
            </a>
          </div>
          <div className="hide-under-w-420">
            <a
              href="mailto:biuro@grupaib.pl"
              style={{ color: `${navBackground ? "#767676" : "#fff"}` }}
            >
              biuro@grupaib.pl
            </a>
          </div>
          <div>
            <Button size="sm" onClick={() => navigate("/kontakt")}>
              Kontakt
            </Button>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
