import { React } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import imgpath from "./images/dol-1.jpg";

const BudynekView = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const paths = {
    0: "m1204.72 984.358-118.57-5.593-95.08 5.593-118.571 5.593-144.298-7.83-401.573 25.727-8.949 140.942-54.81 1.119-4.475 86.131 531.33 22.372 171.144-6.712 111.858 5.594 126.4-7.83 550.346-25.728-4.474-197.99z",
    1: "m338.315 807.51-11.687 200.338L728.2 982.121l144.298 7.83 213.65-11.186 118.57 5.593 540.279 41.388-2.772-188.717-536.413-70.405-213.448 17.88-112.87-11.175-137.456-24.585z",
    2: "m338.315 807.51 1.887-19.761 14.312-3.303 9.359-157.99-76.518-10.46-4.954-20.368 11.56-20.918 77.068-7.707 347.061-87.08 319.55 71.81 209.443-32.314 512.238 132.846 39.236 73.623-17.633-1.47 3.674 98.451-42.372 14.16-536.413-70.405-213.448 17.88-250.326-35.76z",
    3: "m293.96 574.71 216.687-413.761 506.654 166.565 227.096-67.975-.772-49.436 19.31-5.407 45.575 19.31-.773 15.45 81.106-21.629-2.317-20.855 19.31-5.407 30.126 11.586 101.961-27.808 221.398 476.922-512.238-132.846-209.443 32.314-319.55-71.81-347.06 87.08z",
  };

  const availableCount = (floor) => {
    const available = props.data.filter(
      (item) => item.status === "dostepny" && item.pietro.toString() === floor
    );
    return available.length;
  };

  const reservationCount = (floor) => {
    const available = props.data.filter(
      (item) => item.status === "rezerwacja" && item.pietro.toString() === floor
    );
    return available.length;
  };

  const determineColor = (floor) => {
    if (availableCount(floor)) return "green";
    if (reservationCount(floor)) return "yellow";
    return "red";
  };

  const determineCursor = (floor) => {
    return availableCount(floor) || reservationCount(floor)
      ? "pointer"
      : "default";
  };

  const renderStyle = (floor) => {
    return {
      fill: determineColor(floor),
      stroke: determineColor(floor),
      strokeWidth: 0,
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeOpacity: 0.4,
      fillOpacity: 0.0,
      cursor: determineCursor(floor),
    };
  };

  const renderPaths = () => {
    return Object.keys(paths).map((p) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a data-tip data-for={p} style={{ outline: "none" }} key={p}>
        <path
          style={renderStyle(p)}
          d={paths[p]}
          onClick={() => navigate(`/poziom/${params.budynek}/${p}`)}
        />
      </a>
    ));
  };

  const renderTooltips = () => {
    return Object.keys(paths).map((floor) => (
      <ReactTooltip id={floor} type={"light"} key={floor}>
        <span>{`Dostępne: ${availableCount(floor)}`}</span>
      </ReactTooltip>
    ));
  };

  return (
    <>
      <svg
        viewBox="0 0 2666.667 1600"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <image
          width={2666.667}
          height={1600}
          preserveAspectRatio="none"
          xlinkHref={imgpath}
        />
        {props.data && renderPaths()}
      </svg>
      {renderTooltips()}
    </>
  );
};

export default BudynekView;
