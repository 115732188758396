export const getStatusLabel = (status) => {
  if (status === "dostepny") return "Dostępny";
  if (status === "rezerwacja") return "Rezerwacja";
  return "Niedostępny";
};

export const numberString = (number) => {
  return number
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
