import * as React from "react";
import { Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import BirdView from "./BirdView";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

const Oferta = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://bialadolina.com/api/lot-ptakas").then(
      (result) => result.data && setData(result.data)
    );
  }, []);

  const renderButtons = () => {
    const filtered = data.filter((item) => item.status === "dostepny");
    return (
      <div className="accessibility-buttons-container">
        {filtered.map((item) => (
          <Button
            onClick={() => navigate(`/budynek/${item.budynek}`)}
          >{`Budynek ${item.budynek}`}</Button>
        ))}
      </div>
    );
  };

  return (
    <div className="oferta">
      <div className="bkg-image-container">
        <div className="slogan">
          <Container>
            <h1>Wybierz budynek</h1>
          </Container>
        </div>
        <BirdView className="birdview" />
      </div>
      <div className="mobile-accessibility">
        <div>
          <div className="mobile-slogan">Kliknij na budynek powyżej</div>
          <div className="mobile-subslogan">
            lub na jeden z przycisków poniżej
          </div>
        </div>
        {renderButtons()}
      </div>
    </div>
  );
};

export default Oferta;
