import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import imgpath from "../images/parter.png";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import { getStatusLabel } from "../utils";

const Parter = (props) => {
  const paths = {
    5: "m519.178 428.937 2.344-267.207 292.99 2.344-1.172 353.932-296.506-3.516",
    6: "m516.834 514.49 296.506 3.516 1.172 359.792-169.934-1.172 1.172 100.789-198.061 1.172 1.172-462.925",
    7: "M448.86 515.662l-364.479-2.344-1.172 364.48h168.762l1.172 99.617 194.546 1.172",
    8: "m82.037 162.902 294.162 1.172 1.172 349.244H84.381",
  };

  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    Axios.get("https://bialadolina.com/api/mieszkanias").then((result) => {
      if (result.data) {
        const floorItems = result.data.filter(
          (b) =>
            b.budynek === params.budynek.toUpperCase() &&
            b.pietro.toString() === params.poziom.toString()
        );
        if (floorItems.length) {
          setData(floorItems);
        } else {
          navigate("/");
        }
      }
    });
  }, [navigate, params.budynek, params.poziom]);

  const determineColor = (status) => {
    if (status === "dostepny") return "green";
    if (status === "rezerwacja") return "yellow";
    return "red";
  };

  const determineCursor = (status) => {
    if (status === "dostepny" || status === "rezerwacja") return "pointer";
    return "default";
  };

  const renderStyle = (item) => {
    return {
      fill: determineColor(item.status),
      stroke: determineColor(item.status),
      strokeWidth: 5,
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeOpacity: 0.4,
      fillOpacity: 0.3,
      cursor: determineCursor(item.status),
    };
  };

  const renderPaths = (items) => {
    return items.map((item) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        data-tip
        data-for={item.id.toString()}
        style={{ outline: "none" }}
        key={item.id.toString()}
      >
        <path
          style={renderStyle(item)}
          d={paths[item.numer_lokalu]}
          onClick={() =>
            item.status !== "niedostepny" &&
            navigate(`/m/${item.budynek}/${item.numer_lokalu}`)
          }
        />
      </a>
    ));
  };

  const renderTooltips = (items) => {
    return items.map((item, i) => (
      <ReactTooltip id={item.id.toString()} type={"light"} key={i}>
        <span>{`${item.numer_lokalu} - ${getStatusLabel(item.status)}`}</span>
      </ReactTooltip>
    ));
  };

  return (
    <>
      <svg
        viewBox="0 0 896 1036"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <image
          width={896}
          height={1036}
          preserveAspectRatio="none"
          xlinkHref={imgpath}
        />
        {data && renderPaths(data)}
      </svg>
      {data && renderTooltips(data)}
    </>
  );
};

export default Parter;
