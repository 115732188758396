import * as React from "react";
import { Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import goraImage from "./images/gora.jpg";
import mountainsTreesImage from "./images/mountains-trees.svg";
import climberImage from "./images/climber.svg";
import riverImage from "./images/river.svg";
import dolImage from "./images/dol-1.jpg";
import dol2Image from "./images/dol-2.jpg";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";

const App = () => {
  const navigate = useNavigate();
  return (
    <div className="index">
      <div className="bkg-image-container">
        <img src={goraImage} alt="Biała Dolina - widok z lotu ptaka" />
        <div className="slogan">
          <Container>
            <h1>Zamieszkaj w Górach Izerskich w Szklarskiej Porębie</h1>
            <Button onClick={() => navigate("/oferta")}>Zobacz ofertę</Button>
          </Container>
        </div>
      </div>
      <div>
        <Fade bottom>
          <div className="description-container p-5">
            <div className="image-container p-4" style={{ flex: "1 1 0" }}>
              <img src={mountainsTreesImage} alt="Natura" />
            </div>
            <div className="text-container" style={{ flex: "2 1 0" }}>
              <h1 className="title">
                Osiądź na wzgórzach Białej Doliny wśród szumu drzew i górskich
                potoków.
              </h1>
              <p className="content">
                Położona na uboczu, przypomina czasy kiedy do Izerskich osad
                docierali wyłącznie szklarze, pasterze i Walonowie. Rozproszona
                wzdłuż potoku Bieleń, dawna osada hutnicza jest dziś częścią
                Szklarskiej Poręby. Zabudowania obejmują malownicze śródleśne
                polany na wysokości 650 - 850 m n.p.m..
              </p>
              <div className="button-container">
                <Button onClick={() => navigate("/oferta")}>
                  Zobacz dostępne mieszkania
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <Fade bottom>
        <div className="w-100 d-flex">
          <img
            src={dolImage}
            className="m-auto h-100"
            style={{ maxHeight: "80vh", maxWidth: "100vw" }}
            alt="Biała Dolina - wizualzacja"
          />
        </div>
      </Fade>
      <Fade bottom>
        <div>
          <div className="description-container p-5">
            <div className="image-container p-4" style={{ flex: "1 1 0" }}>
              <img src={climberImage} alt="Natura" />
            </div>
            <div className="text-container" style={{ flex: "2 1 0" }}>
              <h1 className="title">Aktywny wypoczynek</h1>
              <p className="content">
                <ul>
                  <li>Bezpośrednie wejście na szlaki piesze i rowerowe</li>
                  <li>
                    Bliskie położenie polany Jakuszyckiej - najlepszego ośrodka
                    narciarstwa biegowego w Polsce
                  </li>
                  <li>10 minut samochodem do Ski Arena Szrenica</li>
                  <li>16 minut samochodem do Harrachova</li>
                </ul>
              </p>
              <div className="button-container">
                <Button onClick={() => navigate("/oferta")}>
                  Zobacz ofertę
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="w-100 d-flex">
          <img
            src={dol2Image}
            className="m-auto h-100"
            style={{ maxHeight: "80vh", maxWidth: "100vw" }}
            alt="Biała Dolina - wizualzacja"
          />
        </div>
      </Fade>
      <Fade bottom>
        <div className="description-container p-5">
          <div className="image-container p-4" style={{ flex: "1 1 0" }}>
            <img src={riverImage} alt="Natura" />
          </div>
          <div className="text-container" style={{ flex: "2 1 0" }}>
            <h1 className="title">Lokalizacja</h1>
            <p className="content">
              Inwestycja znajduje się pod adresem ul. Żeromskiego 15 w
              Szklarskiej Porębie.
            </p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "380px",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2519.802835058356!2d15.491609215634105!3d50.834815867475704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470edabf3f0dc74b%3A0x5cf43c09943baa02!2s%C5%BBeromskiego%2015%2C%2058-580%20Szklarska%20Por%C4%99ba!5e0!3m2!1sen!2spl!4v1642984906780!5m2!1sen!2spl"
            style={{ border: "0", width: "100%", height: "100%" }}
            allowFullScreen=""
            loading="lazy"
            title="google maps"
          ></iframe>
        </div>
      </Fade>
    </div>
  );
};

export default App;
