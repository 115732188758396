import React from "react";
// import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.scss";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Oferta from "./Oferta";
import CustomNavbar from "./Navbar";
import Budynek from "./Budynek";
import Poziom from "./Poziom";
import Mieszkanie from "./Mieszkanie";
import "bootstrap-icons/font/bootstrap-icons.css";
import Footer from "./Footer";
import Kontakt from "./Kontakt";

const TheApp = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <CustomNavbar />
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/oferta" element={<Oferta />} />
          <Route path="/budynek/:budynek" element={<Budynek />} />
          <Route path="/poziom/:budynek/:poziom" element={<Poziom />} />
          <Route path="/m/:budynek/:mieszkanie" element={<Mieszkanie />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </React.StrictMode>
  );
};

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<TheApp />, rootElement);
} else {
  render(<TheApp />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
