import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Parter from "./Poziomy/Parter";
import Pietro from "./Poziomy/Pietro";
import Przyziemie from "./Poziomy/Przyziemie";
import Poddasze from "./Poziomy/Poddasze";
import Axios from "axios";
import Table from "./Table";
import { Button } from "react-bootstrap";

const Poziom = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState();

  const [data, setData] = useState([]);

  useEffect(() => {
    Axios.get("https://bialadolina.com/api/mieszkanias").then((result) => {
      if (result.data) {
        const floorItems = result.data.filter(
          (item) =>
            item.budynek === params.budynek.toUpperCase() &&
            item.pietro.toString() === params.poziom
        );
        if (floorItems.length) {
          setData(floorItems);
        } else {
          navigate("/");
        }
      }
    });
  }, [navigate, params.budynek, params.poziom]);

  useEffect(() => {
    const renderImage = (poziom) => {
      if (poziom === "0") return <Przyziemie height="auto" />;
      if (poziom === "1") return <Parter height="auto" />;
      if (poziom === "2") return <Pietro height="auto" />;
      if (poziom === "3") return <Poddasze height="auto" />;
      navigate("/");
    };
    setImage(renderImage(params.poziom));
  }, [params.poziom, navigate]);

  return (
    <div className="poziom">
      <div style={{ height: "74px" }} />
      <div className="wrapper">
        <div className="details-container-mobile">
          <div>
            <Button
              variant="secondary"
              onClick={() => navigate(`/budynek/${params.budynek}`)}
              style={{ marginBottom: "2rem" }}
            >
              <i className="bi bi-arrow-left"></i>
            </Button>
            <br />
            <h3>
              Budynek:{" "}
              <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                {params.budynek.toUpperCase()}
              </span>
            </h3>
            <h3>
              Poziom:{" "}
              <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                {params.poziom.toUpperCase()}
              </span>
            </h3>
            <p>
              Kliknij na mieszkanie na rzucie lub liście by zobaczyć więcej
              szczegółów.
            </p>
          </div>
        </div>
        <div className="rzut-container">{image}</div>
        <div className="details-container">
          <div className="details">
            <Button
              variant="secondary"
              onClick={() => navigate(`/budynek/${params.budynek}`)}
              style={{ marginBottom: "2rem" }}
            >
              <i className="bi bi-arrow-left"></i>
            </Button>
            <br />
            <span>
              Budynek:{" "}
              <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                {params.budynek.toUpperCase()}
              </span>
            </span>
            <br />
            <span>
              Poziom:{" "}
              <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                {params.poziom.toUpperCase()}
              </span>
            </span>
          </div>
          <Table data={data} style={{ padding: "1rem" }} />
        </div>
      </div>
    </div>
  );
};

export default Poziom;
