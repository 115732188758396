import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Parter from "./Poziomy/Parter";
import Pietro from "./Poziomy/Pietro";
import Przyziemie from "./Poziomy/Przyziemie";
import Poddasze from "./Poziomy/Poddasze";
import Axios from "axios";
import Table from "./Table";
import { Button } from "react-bootstrap";
import { getStatusLabel, numberString } from "./utils";

const Mieszkanie = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();

  useEffect(() => {
    Axios.get("https://bialadolina.com/api/mieszkanias").then((result) => {
      if (result.data) {
        const mieszkanieData = result.data.filter(
          (item) =>
            item.budynek === params.budynek.toUpperCase() &&
            item.numer_lokalu.toString() === params.mieszkanie
        );
        if (mieszkanieData) {
          setData(mieszkanieData[0]);
        } else {
          navigate("/");
        }
      }
    });
  }, [navigate, params.budynek, params.mieszkanie]);

  const renderMieszkanie = () => {
    return (
      data && (
        <img
          src={process.env.PUBLIC_URL + `/rzuty/${data.numer_lokalu}-cut.png`}
          alt="rzut mieszkania"
          style={{ maxWidth: "100%", maxHeight: "75vh" }}
        />
      )
    );
  };

  const renderKontekstBudynku = () => {
    return (
      <img
        src={process.env.PUBLIC_URL + `/plan-sytuacyjny/${data.budynek}.png`}
        alt="lokalizacja budynku"
        style={{ height: "180px" }}
      />
    );
  };

  const renderKontekstMieszkania = () => {
    return (
      <img
        src={
          process.env.PUBLIC_URL + `/kontekst-budynku/${data.numer_lokalu}.png`
        }
        alt="lokalizacja na piętrze"
        style={{ height: "180px" }}
      />
    );
  };

  const renderDetails = () => {
    return (
      <div className="mieszkanie-details">
        <table>
          <tr>
            <td>Budynek</td>
            <td>
              <b>{data.budynek}</b>
            </td>
          </tr>
          <tr>
            <td>Nr lokalu</td>
            <td>
              <b>{data.numer_lokalu}</b>
            </td>
          </tr>
          <tr>
            <td>Piętro</td>
            <td>
              <b>{data.pietro}</b>
            </td>
          </tr>
          <tr>
            <td>Powierzchnia</td>
            <td>
              <b>
                {`${numberString(data.powierzchnia)} m`}
                {<sup>2</sup>}
              </b>
            </td>
          </tr>
          <tr>
            <td>Cena netto</td>
            <td>
              <b>
                {data.cena_netto
                  ? numberString(data.cena_netto.toFixed(2))
                  : "-"}
              </b>
            </td>
          </tr>
          <tr>
            <td>Cena brutto</td>
            <td>
              <b>
                {data.cena_brutto
                  ? numberString(data.cena_brutto.toFixed(2))
                  : "-"}
              </b>
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <b style={data.status === "rezerwacja" ? { color: "orange" } : {}}>{getStatusLabel(data.status)}</b>
            </td>
          </tr>
        </table>
      </div>
    );
  };

  const renderBackButton = () => {
    return (
      <Button
        variant="secondary"
        onClick={() => navigate(`/poziom/${data.budynek}/${data.pietro}`)}
      >
        <i className="bi bi-arrow-left"></i>
      </Button>
    );
  };

  const renderPDFButton = () => {
    return (
      <a
        href={
          process.env.PUBLIC_URL +
          `/metryczki/${data.budynek}/${data.numer_lokalu}.pdf`
        }
      >
        <Button>
          <i className="bi bi-file-earmark-pdf"></i>
          <span> Zobacz PDF</span>
        </Button>
      </a>
    );
  };

  const renderPZTButton = () => {
    return (
      <a href={process.env.PUBLIC_URL + `/plan-sytuacyjny/PZT-ZMIENIONE_MIEJSCA_POST.pdf`}>
        <Button type="success">
          <i className="bi bi-map"></i>
          <span> Plan zagospodarowania terenu</span>
        </Button>
      </a>
    );
  };

  const renderParkingInfo = () => {
    return data.miejsce_postojowe ? <span>
    Przypisane miejsce postojowe: <b>{data.miejsce_postojowe}</b></span> : <span>
    Poszczególne miejsca postojowe dla mieszkań zostaną przydzielone
    przez dewelopera.</span>;
  }

  return data ? (
    <div>
      <div style={{ height: "74px" }} />
      <div className="mieszkanie">
        <div
          className="desktop-hide"
          style={{
            marginRight: "auto",
            paddingLeft: "2rem",
            paddingTop: "2rem",
          }}
        >
          {renderBackButton()}
        </div>
        <div className="rzut-container">{renderMieszkanie()}</div>
        <div className="info">
          <div className="mobile-hide" style={{ marginBottom: "2rem" }}>
            {renderBackButton()}
          </div>
          <div className="images-container">
            <div>{renderKontekstBudynku()}</div>
            <div>{renderKontekstMieszkania()}</div>
          </div>
          <div>{renderDetails()}</div>
          <div>{renderPDFButton()}</div>
          <div className="pt-2">{renderPZTButton()}</div>
          <div style={{paddingTop: '0.5rem'}}>
            {renderParkingInfo()}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>Ładowanie danych...</div>
  );
};

export default Mieszkanie;
