import * as React from "react";
import imgpath from "../images/poddasze.png";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import { getStatusLabel } from "../utils";

const Poddasze = (props) => {
  const paths = {
    13: "M531.528 180.776h84.135l.568 144.394h200.673l.569 141.551 82.43-2.274-.57 126.771-81.165.236v126.16h-202.98l1.249 26.232h-84.315",
    14: "m526.737 527.574.837 226.76 88.278.419.836 144.34 6.694.418-.98 87.786H296.765l-.577-87.76h7.506l.577-181.87-200.923-.578V571.593l-29.446-1.155v-98.152h6.351V325.057h224.018l1.155-144.341 77.367 1.154 1.155 344.111",
  };

  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    Axios.get("https://bialadolina.com/api/mieszkanias").then((result) => {
      if (result.data) {
        const floorItems = result.data.filter(
          (b) =>
            b.budynek === params.budynek.toUpperCase() &&
            b.pietro.toString() === params.poziom.toString()
        );
        if (floorItems.length) {
          setData(floorItems);
        } else {
          navigate("/");
        }
      }
    });
  }, [navigate, params.budynek, params.poziom]);

  const determineColor = (status) => {
    if (status === "dostepny") return "green";
    if (status === "rezerwacja") return "yellow";
    return "red";
  };

  const determineCursor = (status) => {
    if (status === "dostepny" || status === "rezerwacja") return "pointer";
    return "default";
  };

  const renderStyle = (item) => {
    return {
      fill: determineColor(item.status),
      stroke: determineColor(item.status),
      strokeWidth: 5,
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeOpacity: 0.4,
      fillOpacity: 0.3,
      cursor: determineCursor(item.status),
    };
  };

  const renderPaths = (items) => {
    return items.map((item) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        data-tip
        data-for={item.id.toString()}
        style={{ outline: "none" }}
        key={item.id.toString()}
      >
        <path
          style={renderStyle(item)}
          d={paths[item.numer_lokalu]}
          onClick={() =>
            item.status !== "niedostepny" &&
            navigate(`/m/${item.budynek}/${item.numer_lokalu}`)
          }
        />
      </a>
    ));
  };

  const renderTooltips = (items) => {
    return items.map((item, i) => (
      <ReactTooltip id={item.id.toString()} type={"light"} key={i}>
        <span>{`${item.numer_lokalu} - ${getStatusLabel(item.status)}`}</span>
      </ReactTooltip>
    ));
  };

  return (
    <>
      <svg
        viewBox="0 0 948 1074.667"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <image
          width={948}
          height={1074.667}
          preserveAspectRatio="none"
          xlinkHref={imgpath}
        />
        {data && renderPaths(data)}
      </svg>
      {data && renderTooltips(data)}
    </>
  );
};

export default Poddasze;
