import React from "react";
import { useNavigate } from "react-router-dom";
import { getStatusLabel, numberString } from "./utils";

const Table = (props) => {
  const navigate = useNavigate();
  const renderHead = () => {
    return (
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Piętro</th>
          <th scope="col">Powierzchnia</th>
          <th className="mobile-hide" scope="col">
            Cena Netto
          </th>
          <th scope="col">Cena Brutto</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
    );
  };

  const renderWarning = (items) => {
    const filtered = items.filter((item) => item.status !== "niedostepny");
    if (!filtered.length)
      return (
        <div style={{ textAlign: "center", padding: "12px" }}>
          Brak dostępych mieszkań
        </div>
      );
  };

  const renderRows = (items) => {
    return items.map((item) => (
      <tr
        onClick={() =>
          item.status !== "niedostepny" &&
          navigate(`/m/${item.budynek}/${item.numer_lokalu}`)
        }
        className={`${item.status !== "niedostepny" ? "available-row" : ""}`}
      >
        <td>{item.numer_lokalu}</td>
        <td>{item.pietro}</td>
        <td>
          {`${item.powierzchnia ? numberString(item.powierzchnia) : '-'} m`}
          {<sup>2</sup>}
        </td>
        <td className="mobile-hide">
          {item.cena_netto ? numberString(item.cena_netto.toFixed(2)) : "-"}
        </td>
        <td>
          {item.cena_brutto ? numberString(item.cena_brutto.toFixed(2)) : "-"}
        </td>
        <td style={item.status === "rezerwacja" ? { color: "orange" } : {}}>
          {getStatusLabel(item.status)}
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <table className="table">
        {renderHead()}
        <tbody>{props.data && renderRows(props.data)}</tbody>
      </table>
      {renderWarning(props.data)}
    </div>
  );
};

export default Table;
