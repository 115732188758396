import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import Axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import imgpath from "./images/gora-lighten-1920.jpg";

const BirdView = (props) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://bialadolina.com/api/lot-ptakas").then(
      (result) => result.data && setData(result.data)
    );
  }, []);

  const getItem = (name) => {
    return data.find((item) => item.budynek === name);
  };

  const getColor = (status) => {
    if (status === "dostepny") return "green";
    if (status === "niedostepny") return "grey";
    if (status === "wkrotce") return "yellow";
  };

  const getCursor = (status) => {
    if (status === "dostepny") return "pointer";
    return "default";
  };

  const templateStyle = {
    fillOpacity: 0.5,
  };

  const getStyle = (item) => {
    return {
      fill: getColor(item.status),
      cursor: getCursor(item.status),
      ...templateStyle,
    };
  };

  const displayStatus = (s) => {
    if (s === "dostepny") return "Dostępne mieszkania";
    if (s === "wkrotce") return "Wkrótce";
    return "Niedostępny";
  };

  const renderTooltips = (buildings) => {
    return buildings.map((b) => (
      <ReactTooltip
        id={b.budynek}
        type={b.status === "niedostepny" ? "error" : "light"}
        key={b.id}
      >
        <span>{`${b.budynek} ${displayStatus(b.status)}`}</span>
      </ReactTooltip>
    ));
  };

  const paths = {
    M1: "M1500.57 764.832l-64.652 30.386-82.107-22.628 7.111-56.894 25.861-53.66 54.308 14.87 21.981-10.992 40.73 53.662z",
    M2: "M1174.725 755.78l4.525-57.54 25.861-49.782 47.843 12.284 30.386-8.404 33.619 47.195-.647 51.075-64.651 27.154z",
    M3: "M1133.348 736.385v-50.428l-32.973-46.55-25.86 7.758-49.136-15.516-27.8 49.782v27.8l29.74 10.345 28.446-10.991 27.154 42.023z",
    M4: "m944.564 818.493 2.586-61.42 31.68-51.72 49.357 15.568 26.83-10.343 36.085 51.405-2.915 53.586-34.592-11.24-11.844 23.15-27.637 13.907z",
    M5: "M1318.252 973.657l4.526-66.591 32.972-61.42 59.48 17.457 27.8-12.93 45.903 60.126-2.586 62.065-61.42 33.62-104.735-31.68z",
    M6: "m1016.974 932.28 5.172-65.945 32.326-59.48 54.954 17.457 30.387-12.284 40.73 57.54-.646 58.187-77.582 33.619z",
    M7: "m605.788 932.927 1.94-55.6 41.377-66.592 48.489 18.102 33.619-10.99 35.558 56.247.647 54.954-84.694 36.205z",
    M8: "m238.565 1106.194.647-59.48 51.075-73.703 45.903 20.042 43.963-15.516 37.498 62.065 3.233 60.773-107.322 44.61z",
  };

  const handleClick = (b) => {
    if (b.status === "dostepny") {
      navigate(`/budynek/${b.budynek}`);
    }
  };

  const renderPaths = (buildings) => {
    return buildings.map((b) => {
      return (
        <a data-tip data-for={b.budynek} style={{ outline: "none" }} key={b.id}>
          <path
            onClick={() => handleClick(b)}
            style={getStyle(getItem(b.budynek))}
            d={paths[b.budynek]}
          />
        </a>
      );
    });
  };

  return (
    <>
      <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 1920 1324"
      >
        <image
          width={1920}
          height={1324}
          preserveAspectRatio="none"
          xlinkHref={imgpath}
        />
        <path
          fill="url(#img1)"
          d="M3.264 0h1920v1324h-1920z"
          style={{ fill: "none" }}
        />
        {renderPaths(data)}
      </svg>
      {renderTooltips(data)}
    </>
  );
};
export default BirdView;
