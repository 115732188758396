import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BudynekView from "./BudynekView";
import Table from "./Table";
import Axios from "axios";
import { Button } from "react-bootstrap";

const Budynek = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const tableRef = useRef();

  useEffect(() => {
    Axios.get("https://bialadolina.com/api/mieszkanias").then((result) => {
      if (result.data) {
        const buildingItems = result.data.filter(
          (b) => b.budynek === params.budynek.toUpperCase()
        );
        if (buildingItems.length) {
          setData(buildingItems);
        } else {
          navigate("/");
        }
      }
    });
  }, [navigate, params.budynek]);

  const renderTable = (items) => {
    const filtered = items.filter((item) => item.status !== "niedostepny");
    const sorted = filtered.sort((a, b) => a.numer_lokalu - b.numer_lokalu);
    return <Table data={sorted} />;
  };

  return (
    <div className="budynek">
      <div style={{ height: "74px" }} />
      <div className="infobox-sm">
        <Button
          variant="secondary"
          onClick={() => navigate(`/oferta`)}
          style={{ marginBottom: "1rem" }}
        >
          <i className="bi bi-arrow-left"></i>
        </Button>
        <h5>
          Budynek: <b>{params.budynek.toUpperCase()}</b>
        </h5>
        <p>
          Kliknij na piętro by przejść do rzutu piętra lub na wpis na liście by
          zobaczyć szczegóły mieszkania.
        </p>
      </div>
      <div className="infobox">
        <h5>
          Budynek: <b>{params.budynek.toUpperCase()}</b>
        </h5>
        <p>
          Kliknij na piętro by przejść do rzutu piętra lub na wpis na liście by
          zobaczyć szczegóły mieszkania.
        </p>
        <div className="buttons-container">
          <Button variant="secondary" onClick={() => navigate(`/oferta`)}>
            <i className="bi bi-arrow-left"></i>
          </Button>
          <Button onClick={() => tableRef.current.scrollIntoView()}>
            Lista
          </Button>
        </div>
      </div>
      <BudynekView data={data} />
      <div ref={tableRef}>{renderTable(data)}</div>
    </div>
  );
};

export default Budynek;
