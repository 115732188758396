import React, { useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

const RECAPTCHA_SITE_KEY = "6Len9yYgAAAAAONUvZLushCJl8akYb1wwU0AwmXM";
const EMAILJS_SERVICE_ID = "service_e9gv4h6";
const EMAILJS_TEMPLATE_ID = "template_17rcp8w";
const EMAILJS_PUBLIC_KEY = "O4sFh0mTl2Z_aIKF4";

const Kontakt = () => {
  const [enabled, setEnabled] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const onRecaptchaChange = (value) => {
    if (value) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  const form = useRef();

  const fromEmail = useRef();
  const fromPhone = useRef();
  const message = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_email: fromEmail.current.value,
      from_phone: fromPhone.current.value,
      message: message.current.value,
    };

    emailjs
      .send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams,
        EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setSent(true);
        },
        (error) => {
          setError(true);
        }
      );
  };

  return (
    <div
      style={{
        padding: "2rem",
        margin: "auto",
        maxWidth: "800px",
        minHeight: "560px",
        paddingTop: "5rem",
      }}
    >
      <h3>Zostaw wiadomość, skontaktujemy się z tobą.</h3>
      <form ref={form} onSubmit={sendEmail}>
        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">
            Adres email
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="name@example.com"
            ref={fromEmail}
          />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlInput2" className="form-label">
            Telefon
          </label>
          <input
            type="tel"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="+48 999 999 999"
            ref={fromPhone}
          />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlTextarea1" className="form-label">
            Wiadomość
          </label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            ref={message}
          ></textarea>
        </div>
        <div className="mb-3">
          <button
            type="submit"
            class="btn btn-primary mb-3"
            disabled={!enabled}
          >
            Wyślij
          </button>

          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={onRecaptchaChange}
          />
        </div>
      </form>
      {sent && (
        <small className="d-inline-flex mb-3 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">
          Wiadomość wysłana, skontaktujemy się wkrótce.
        </small>
      )}
      {error && (
        <small className="d-inline-flex mb-3 px-2 py-1 fw-semibold text-danger bg-danger bg-opacity-10 border border-danger border-opacity-10 rounded-2">
          Nie udało się wysłać wiadomości, prosimy o samodzielny kontakt.
        </small>
      )}
    </div>
  );
};

export default Kontakt;
